const locales = [
  {
    code: 'nl',
    language: 'nl-NL',
    storeCode: 'tra_nl_nl',
    domain: 'https://www.travelbags.nl',
    file: 'nl.json',
    name: 'NL',
    flag: '🇳🇱',
    numberFormats: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
    datetimeFormats: {
      default: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        weekday: 'long',
      },
    },
  },
  {
    code: 'de',
    language: 'de-DE',
    storeCode: 'tra_de_de',
    domain: 'https://www.travelbags.de',
    file: 'de.json',
    name: 'DE',
    flag: '🇩🇪',
    numberFormats: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
    datetimeFormats: {
      default: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        weekday: 'long',
      },
    },
  },
  {
    code: 'nl-be',
    language: 'nl-BE',
    storeCode: 'tra_be_nl',
    domain: 'https://www.travelbags.be',
    files: [
      'nl.json',
      'nl-be.json',
    ],
    name: 'BE',
    flag: '🇧🇪',
    numberFormats: {
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
    },
    datetimeFormats: {
      default: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        weekday: 'long',
      },
    },
  },
]

const numberFormats: any = {}
const datetimeFormats: any = {}
locales.forEach((locale) => {
  numberFormats[locale.code] = locale.numberFormats
  datetimeFormats[locale.code] = locale.datetimeFormats
})

const hasEN = locales.some((locale) => locale.code === 'en')

export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: {
    'be-nl': ['nl'],
    'be-fr': ['fr'],
    'default': [(hasEN ? 'en' : locales[0].code)],
  },
  availableLocales: locales.map(locale => locale.code),
  numberFormats,
  datetimeFormats,
  missingWarn: true,
  fallbackWarn: true,
  formatFallbackMessages: true,
}))
